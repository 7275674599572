import React, { useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import MarkdownStringRenderer from "../components/MarkdownStringRenderer";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import PAGE_CONTENTS from "../../content/pages/team";

export default function Team() {
  // Setting state for use inside the Modal so the correct info gets passed down.
  const [modalContent, setModalContent] = useState(null);

  function handleClick(member) {
    // If there's no bio, don't open the Modal.
    if (member.bio === "") return;

    // Set state for member and then open the Modal.
    setModalContent(member);
  }

  return (
    <Layout>
      <SEO title="Team" />

      <section className="header_extension no_bg">
        <h1 className="team-title">{PAGE_CONTENTS.title}</h1>
      </section>

      <section className="container team">
        {PAGE_CONTENTS.teamMembers.map((member) => {
          return (
            <article
              className={`team-member  ${member.bio === "" ? `no-bio` : null}`}
              onClick={() => handleClick(member)}
            >
              <div className="team-member-image">
                <img src={member.photo} alt={member.name} />
              </div>
              <h2 className="team-member-name">{member.name}</h2>
              <p className="team-member-role">{member.role}</p>
              {member.bio && (
                <button className="team-member-bio">
                  Read Bio
                  <span>
                    <svg
                      width="12"
                      height="12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.48 3.555a.815.815 0 0 0-.814.815v5.704c0 .45.365.815.815.815h5.704c.45 0 .814-.365.814-.815"
                        stroke="#D73767"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.073.667H4.37a.815.815 0 0 0-.814.814v5.704c0 .45.364.815.815.815h5.703c.45 0 .815-.365.815-.815V1.481a.815.815 0 0 0-.815-.814Z"
                        stroke="#D73767"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              )}

              {/* Team Modal */}
              {modalContent && (
                <DialogOverlay
                  className="team-modal-overlay"
                  onDismiss={() => setModalContent(null)}
                >
                  <DialogContent className="team-modal-content">
                    <div className="team-modal-image">
                      <img src={modalContent.photo} alt="" />
                    </div>
                    <div className="team-modal-info">
                      <h1>{modalContent.name}</h1>
                      <h2>{modalContent.role}</h2>
                      {modalContent && (
                        <MarkdownStringRenderer
                          markdownString={modalContent.bio}
                        />
                      )}
                    </div>
                    <button
                      className="team-modal-close-icon"
                      onClick={() => setModalContent(null)}
                    >
                      <svg
                        width="40"
                        height="40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="40" height="40" rx="20" fill="#FBF1F3" />
                        <path
                          d="M26.6 13.4 13.4 26.6M13.4 13.4l13.2 13.2"
                          stroke="#D73767"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </DialogContent>
                </DialogOverlay>
              )}
            </article>
          );
        })}
      </section>
    </Layout>
  );
}
